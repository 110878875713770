import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroupDirective, NgForm, FormGroup, FormArray } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { Projectdetails } from './projectdetails.module';
import { trigger, state, style, transition, animate } from '@angular/animations';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

// initiate interface for object final list
interface finalLIST {
  firstName: string,
  secondName?: string,
  phone: string,
  email: string,
  projects: Projectdetails[]

};

@Component({
  selector: 'app-mainform',
  templateUrl: './mainform.component.html',
  styleUrls: ['./mainform.component.scss'],
  animations: [
    trigger('block', [
      state('in', style(
        {
          opacity: 1,
          transform: 'translateY(0)',
        })),
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'translateY(-10px)'
        }),
        animate(300)
      ]),
      transition('* => void', [
        animate(200, style({
          opacity: 0,
          transform: 'translateY(-10px)'
        }))
      ])
    ]),
    trigger('erM', [
      state('in', style(
        {
          opacity: 1,
        })),
      transition('void => *', [
        style({
          opacity: 0,
        }),
        animate(300)
      ]),
      transition('* => void', [
        animate(200, style({
          opacity: 0,
        }))
      ])
    ])
  ]
})

export class MainformComponent implements OnInit {
  projecsForm: FormGroup;  // initiate form groupe for form
  matcher = new MyErrorStateMatcher(); // initiate error matcher
  listSelectedProjects = []; // here will be stored selected Projects
  fL = {} as finalLIST; // store interface of final List

  // initiate list of project that will be available
  listProjects = ['Revolution',
    'Handy',
    'Google',
    'Westbourne IT Global',
    'Tesla',
    'Microsoft'
  ];

  placeHolderProjectField = "Add a new project!"; // set text label for project field
  isTheSumCorret: boolean = true; // based on it will show the error message for Sum  
  showOutput: boolean = false;  // based on it will be shown the output section with result , beneath form

  constructor() { }
  ngOnInit() {

    // create form group
    this.projecsForm = new FormGroup({
      'firstName': new FormControl(null, Validators.required),
      'secondName': new FormControl(null),
      'phone': new FormControl(null, Validators.required),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'projectsData': new FormGroup({
        'selectedPrjs': new FormArray([])

      }),

    });
  }

  // method will fire when X button will be clicked (remove on project from form)
  onDeleteProject(index: number) {
    // add one project to initial list of projects
    this.listProjects.push(this.listSelectedProjects[index])
    // remove one project from Selected projets list
    this.listSelectedProjects.splice(index, 1);
    // remove one item from form array 
    (<FormArray>this.projecsForm.get('projectsData.selectedPrjs')).removeAt(index);
    this.placeHolderProjectField = "Add a Project";
  }

  onSelectedList(event: MatSelectChange) {

    const control = new FormControl(null, [Validators.required, Validators.maxLength(3), this.noPositive.bind(this)]); // create a control from new field
    let sValue = event.source.value; //get the value of Selectet Project  Item
    let indexOfProject = this.listProjects.indexOf(sValue); //get the index from our list
    this.listSelectedProjects.push(sValue); // add to Selected Project List
    (<FormArray>this.projecsForm.get('projectsData.selectedPrjs')).push(control);     // add control to Form Array
    this.listProjects.splice(indexOfProject, 1); //remove from Project List
    // change place holder if list of project is empty
    this.listProjects.length == 0 ? this.placeHolderProjectField = "You have selected all projects !" : this.placeHolderProjectField = "Add a new project!";

    event.source.value = ""; //reset the value

    this.isTheSumCorret = false;

  }

  getControls() {
    // return from array controls
    return (<FormArray>this.projecsForm.get('projectsData.selectedPrjs')).controls;
  }

  onSubmit() {

    // Fill the results 
    // if form status is valid and sum equal with 100 
    if ((this.projecsForm.status === 'VALID') && (this.onCheckSum() === 100)) {

      this.fL.firstName = this.projecsForm.value.firstName; // get first name from form
      this.fL.secondName = this.projecsForm.value.secondName; // get second name from form
      this.fL.phone = this.projecsForm.value.phone; // get phone from form
      this.fL.email = this.projecsForm.value.email; // get email from form
      this.fL.projects = []; // initiate projects array
      // go through selected projects list
      for (let i = 0; i < this.listSelectedProjects.length; i++) {
        // add new item to array of projects
        let newProject: Projectdetails[] =
          [
            new Projectdetails(
              this.listSelectedProjects[i],
              this.projecsForm.value.projectsData.selectedPrjs[i]
            )
          ]
        // push projects array to object  
        this.fL.projects.push(...newProject);
      }

      // show from status in console
      console.log(this.projecsForm.status);
      // show the result object in console
      console.log(this.fL);
      // switch variables to true
      this.isTheSumCorret = true;
      this.showOutput = true;
    } else {
      // if form is not valid return false
      this.isTheSumCorret = false;
      this.showOutput = false;
    }

  }


  //  method thet check the sum of all project, it is used and in template(html)
  onCheckSum() {
    let sumPrecentage: number = 0;
    for (let i = 0; i < this.listSelectedProjects.length; i++) {
      sumPrecentage += this.projecsForm.value.projectsData.selectedPrjs[i];
    }
    return sumPrecentage;
  }

  // method for a custom form control - is checking if the number is positive and between 1 and 100
  private noPositive(control: FormControl): { [s: string]: boolean } {

    if (control.value) {
      if (Number(control.value)) {


        if ((Number(control.value) < 1) || (Number(control.value) > 100)) {
          return { 'noPositive': true };
        }
      }
    }
    return null;


  }


  // method that return the errors
  getErrorFormG(control: string, index: number, errorName: string) {
    if (this.projecsForm.get(control + '.' + index).errors) {
      return this.projecsForm.get(control + '.' + index).errors[errorName];
    }
  }


}
